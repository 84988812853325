import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const PsychoanalysisBlock = () => {
  const theme = useTheme();

  return (
    <Box sx={{
      textAlign: 'justify',
      my: 20,
      mx:'auto',
      px: 4,
      maxWidth:'1100px',
      [theme.breakpoints.down('md')]: {
        my: 10,
      }
    }}>
      <Typography variant="h2" gutterBottom>
        Что такое психоанализ?
      </Typography>
      <Typography variant="body1" sx={{ mx: 'auto' }}>
        <p>
          Психоанализ — это особый вид терапии, который помогает заглянуть в глубины бессознательного. В процессе нашей работы постепенно проявляются скрытые переживания и внутренние конфликты, которые могут незаметно влиять на вашу жизнь. Это не просто разговор — это возможность понять, почему прошлое продолжает влиять на ваше настоящее.
        </p>
        <p>
          Психоанализ помогает освободиться от старых эмоциональных переживаний и отношений, которые застряли в вашем внутреннем мире и мешают двигаться вперед. Эти давние переживания могут незаметно удерживать вас на месте, создавая препятствия. Вместе мы разбираемся с ними, чтобы вы могли свободно двигаться в будущее, где прошлое не будет разрушать настоящее.
        </p> 
      </Typography>
    </Box>
  );
};

export default PsychoanalysisBlock;
