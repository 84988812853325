import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Lora", "Georgia", serif',
    h1: {
      fontFamily: '"Lora", "Georgia", serif',
      fontSize: '2.5rem',
      fontWeight: 400, // Нежирный
      lineHeight: 1.2,
      color: '#800020', // Акцентный цвет
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h2: {
      fontFamily: '"Lora", "Georgia", serif',
      fontSize: '2rem',
      fontWeight: 400, // Нежирный
      lineHeight: 1.3,
      color: '#800020', // Акцентный цвет
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontFamily: '"Lora", "Georgia", serif',
      fontSize: '1.75rem',
      fontWeight: 400, // Нежирный
      lineHeight: 1.4,
      color: '#800020', // Акцентный цвет
      '@media (max-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    body1: {
      fontFamily: '"Lora", "Georgia", serif',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.6,
      '@media (max-width:600px)': {
        fontSize: '0.875rem',
      },
    },
    body2: {
      fontFamily: '"Lora", "Georgia", serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.6,
      '@media (max-width:600px)': {
        fontSize: '0.75rem',
      },
    },
    button: {
      fontFamily: '"Lora", "Georgia", serif',
      fontSize: '1rem',
      textTransform: 'none', // Убираем автоматическое изменение регистра
    },
  },
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: '#800020', // Бордо
    },
    secondary: {
      main: '#E0E0E0', // Светло-серый
    },
  },
  spacing: 8, // базовый размер отступа
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#800020', // Бордо
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#800020', // Бордо
          '&:hover': {
            backgroundColor: '#600016', // Темнее бордо
          },
        },
        outlinedPrimary: {
          borderColor: '#800020', // Бордо
          color: '#800020', // Бордо
          '&:hover': {
            borderColor: '#600016', // Темнее бордо
            color: '#600016', // Темнее бордо
          },
        },
      },
    },
    MuiContainer: {
        styleOverrides: {
          maxWidthXs: {
            paddingLeft: '16px', // отступы для xs
            paddingRight: '16px',
          },
          maxWidthSm: {
            paddingLeft: '24px', // отступы для sm
            paddingRight: '24px',
          },
          maxWidthMd: {
            paddingLeft: '32px', // отступы для md
            paddingRight: '32px',
          },
          maxWidthLg: {
            paddingLeft: '40px', // отступы для lg
            paddingRight: '40px',
          },
          maxWidthXl: {
            paddingLeft: '48px', // отступы для xl
            paddingRight: '48px',
          },
        },
        root: {
            paddingLeft: '24px', // отступы от краев экрана
            paddingRight: '24px',
            '@media (min-width:600px)': {
              paddingLeft: '32px', // большие отступы на более широких экранах
              paddingRight: '32px',
            },
            '@media (min-width:1200px)': {
              maxWidth: '1200px', // ограничение ширины контента на десктопе
            },
          },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#eeeeee', // Используем ваш цвет из палитры
          },
        },
      },
  
  },
    // Стиль для отступов между блоками
    spacing: (factor) => `${factor * 8}px`,
});

export default theme;
