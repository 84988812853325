import React, { createContext, useState, useContext } from 'react';

const PrivacyPolicyContext = createContext();

export const PrivacyPolicyProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <PrivacyPolicyContext.Provider value={{ open, openModal, closeModal }}>
      {children}
    </PrivacyPolicyContext.Provider>
  );
};

export const usePrivacyPolicy = () => useContext(PrivacyPolicyContext);
