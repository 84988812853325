import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const WorkingWith = () => {
  const theme = useTheme();

  return (
    <Box
    sx={{
      position: 'relative',
    }}
  >
    <Box
      sx={{
        backgroundImage: 'url(/img/front_my.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: '0.5',
        zIndex: '-1',   
        left: 0,
        top: 0
      }}
    />
    <Box sx={{
      textAlign: 'justify',
      my: 20,
      mx:'auto',
      px: 4,
      py: 4,
      maxWidth:'100%',
      [theme.breakpoints.down('md')]: {
        my: 10,
      }
    }}>
      <Card sx={{ maxWidth: 1100, boxShadow: 3, mx:'auto', my:4, p:1 }}>
        <CardContent>
          <Typography variant="h3" component="div" gutterBottom>
            С какими проблемами я работаю
          </Typography>
          <Typography variant="body1" color="text.primary">
            <p>
              Психоанализ — это гибкий подход, который помогает разобраться с самым разнообразным спектром проблем и запросов. Независимо от того, что вас беспокоит, будь то внутренние конфликты, трудности в отношениях или неясные чувства, психоанализ может помочь понять глубинные причины и найти пути для их решения.
            </p>
            <p>
              Поскольку психоанализ работает с бессознательным, он позволяет исследовать скрытые аспекты вашего внутреннего мира, которые могут влиять на вашу жизнь. Это значит, что мы можем обращаться к самым различным вопросам и находить индивидуальные подходы, чтобы помочь вам разобраться с тем, что действительно важно
            </p>
          </Typography>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: 1100, boxShadow: 3, mx:'auto', my:4, p:1 }}>
        <CardContent>
          <Typography variant="h3" component="div" gutterBottom>
            С чем не работаю
          </Typography>
          <Typography variant="body1" color="text.primary">
            <p>
              Хотя психоанализ может быть полезен в множестве ситуаций, есть определенные области, в которых я не оказываю помощь. Это связано с особенностями моей профессиональной подготовки и практики. Я не работаю с:
            </p>
            <ul>
              <li>
                Суицидальным поведением
              </li>
              <li>
                Психозами
              </li>
              <li>
                Людьми до 18 лет
              </li>
            </ul>
            <p>
              Эти направления требуют особых знаний и опыта, которых у меня пока нет. Если у вас есть запросы в этих областях, я рекомендую обратиться к специалистам, имеющим соответствующую квалификацию и опыт.
            </p>
          </Typography>
        </CardContent>
      </Card>
    </Box>
    </Box>
  );
};

export default WorkingWith;
