import React from 'react';
import { Box, Button, Typography, SvgIcon } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language'; // Иконка для Дзен
import { useTheme } from '@mui/material/styles';

const VkIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path id="ic-vk" fillRule="evenodd" clipRule="evenodd" d="M17.7703 13.3585C18.301 13.9307 18.8612 14.4692 19.3372 15.0992C19.5475 15.3791 19.7466 15.668 19.8989 15.9929C20.1147 16.4548 19.9192 16.963 19.5442 16.9906L17.2133 16.9894C16.6121 17.0445 16.1325 16.7772 15.7293 16.3233C15.4066 15.9602 15.1077 15.5739 14.7974 15.1986C14.6702 15.0452 14.537 14.9008 14.3779 14.7868C14.0597 14.5586 13.7835 14.6285 13.6017 14.995C13.4165 15.3678 13.3745 15.7806 13.3563 16.1961C13.3313 16.8023 13.1654 16.9616 12.6141 16.9894C11.4358 17.0507 10.3175 16.8539 9.2787 16.1974C8.36284 15.6187 7.65263 14.8017 7.03447 13.8767C5.83089 12.0757 4.90919 10.0967 4.08081 8.06218C3.89434 7.60381 4.03071 7.35776 4.48864 7.34905C5.24905 7.33273 6.00936 7.33389 6.77067 7.34789C7.07973 7.35288 7.28433 7.54865 7.40366 7.87111C7.81507 8.98833 8.31847 10.0513 8.95036 11.0365C9.11863 11.2988 9.29022 11.5611 9.53456 11.7458C9.80487 11.9502 10.0107 11.8824 10.1378 11.5499C10.2185 11.339 10.2539 11.1119 10.2721 10.886C10.3323 10.109 10.3402 9.33337 10.2346 8.5591C10.1697 8.07588 9.92326 7.76306 9.48667 7.67162C9.26388 7.625 9.29705 7.53344 9.40492 7.393C9.59228 7.15078 9.76849 7 10.1198 7H12.7541C13.1688 7.0904 13.2609 7.29616 13.3177 7.75703L13.3199 10.9887C13.3154 11.1672 13.4006 11.6966 13.6916 11.8147C13.9245 11.8987 14.078 11.693 14.2177 11.5299C14.8484 10.7906 15.2986 9.91686 15.7007 9.01214C15.8792 8.61432 16.0327 8.20117 16.1814 7.78838C16.2916 7.48206 16.4645 7.33134 16.7769 7.33802L19.3122 7.34051C19.3874 7.34051 19.4635 7.34173 19.5363 7.35549C19.9635 7.4359 20.0806 7.63888 19.9486 8.09963C19.7407 8.82252 19.3362 9.42493 18.9407 10.0301C18.5179 10.6763 18.0657 11.3003 17.6464 11.9502C17.2611 12.5438 17.2917 12.843 17.7703 13.3585V13.3585Z" fill="currentColor"></path>
  </SvgIcon>
);

const SocialBlock = () => {
  const theme = useTheme();

  return (
    <Box sx={{
      textAlign: 'justify',
      my: 20,
      mx:'auto',
      px: 4,
      maxWidth:'1100px',
      [theme.breakpoints.down('md')]: {
        my: 10,
      }
    }}>
      <Typography variant="h2" gutterBottom>
        Психоанализ и не только
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Подписывайтесь на ту сеть, которая вам ближе. Вас ждут интересные истории о психоанализе, современном мире и факты, которые могут вас вдохновить.      </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        }}}>
        <Button
          variant="outlined"
          startIcon={<YouTubeIcon />}
          sx={{ color: '#FF0000', borderColor: '#FF0000', '&:hover': { borderColor: '#CC0000', color: '#CC0000' } }}
          href="https://www.youtube.com/channel/UCQr8_4KM5Lcx5fQW6vMlABg"
        >
          YouTube
        </Button>
        <Button
          variant="outlined"
          startIcon={<InstagramIcon />}
          sx={{ color: '#E1306C', borderColor: '#E1306C', '&:hover': { borderColor: '#C13584', color: '#C13584' } }}
          href="https://www.instagram.com/ryabov.psy/"
        >
          Instagram
        </Button>
        <Button
          variant="outlined"
          startIcon={<VkIcon />}
          sx={{ color: '#4680C2', borderColor: '#4680C2', '&:hover': { borderColor: '#4C75A3', color: '#4C75A3' } }}
          href="https://vk.com/ryabov_psy"
        >
          VK
        </Button>
        <Button
          variant="outlined"
          startIcon={<LanguageIcon />}
          sx={{ color: '#000000', borderColor: '#000000', '&:hover': { borderColor: '#333333', color: '#333333' } }}
          href="https://dzen.ru/ryabov_psy"
        >
          Дзен
        </Button>
      </Box>
    </Box>
  );
};

export default SocialBlock;
