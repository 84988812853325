import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const completedCourses = [
  { year: 2015, title: 'Прикладная психология', institution: 'Российский университет дружбы народов' },
  { year: 2018, title: 'Психиатрия для практикующих психологов. Теория и практика', institution: 'В.Н.Трофимов, МОЦАП' },
  { year: 2018, title: 'Дифференциальная диагностика личностных расстройств пограничного уровня и их лечение в терапии сфокусированной на переносе', institution: 'Отто Кернберг' },
  { year: 2018, title: 'Психоанализ Фрейда-Лакана. Введение в Лакана: субъект Фрейда', institution: 'В.А.Мазин, А.Юран, ВЕИП' },
  { year: 2022, title: 'Тревога. Экзистенциальный подход', institution: 'А.Лызлов' },
  { year: 2022, title: 'Психоанализ юного субъекта. Ребенок зеркала. Клиника аутизма', institution: 'А.Юран, ВЕИП' },
  { year: 2023, title: 'Психоанализ Фрейда-Лакана: Субъект Фрейда. Часть 2: Лакан читает Фрейда', institution: 'В.А.Мазин, А.Юран, ВЕИП' },
  { year: 2023, title: 'Психоанализ Фрейда-Лакана: Субъект Лакана', institution: 'В.А.Мазин, А.Юран, ВЕИП' },
  { year: 2023, title: 'Психоанализ Фрейда-Лакана: По ту сторону фантазма', institution: 'В.А.Мазин, А.Юран, ВЕИП' },
  { year: 2023, title: 'Психоанализ Фрейда-Лакана. Клиника Лакана', institution: 'В.А.Мазин, А.Юран, ВЕИП' },
  { year: 2023, title: 'Теория поля и сновидение сессии. Новые перспективы психоаналитической техникиа', institution: 'Джузеппе Чивитарезе, МАПП' },
  { year: 2024, title: 'Сновидения в психоанализе и психотерапии. Перспектива теории поля', institution: 'Джузеппе Чивитарезе, МАПП' },
  { year: 2024, title: 'Парная терапия: реляционный психоаналитический подход', institution: 'Филип Рингстром, МАПП' },
  // Добавьте свои курсы
];

const ongoingCourses = [
  { year: 2025, title: 'Психоанализ и консультативная психология. Магистратура', institution: 'ВЕИП' },
  { year: 2025, title: 'Экспедиции в неизведанные области психической жизни. Клинический метод и мышление Уилфреда Биона', institution: 'МАПП' },
  { year: 2024, title: 'Психоаналитическое слушание: методы, ограничения и инновации', institution: 'Салман Ахтар, МАПП' },
  // Добавьте свои текущие курсы
];

const CoursesBlock = () => {
  const theme = useTheme();

  return (
    <Box sx={{
      textAlign: 'justify',
      my: 20,
      mx:'auto',
      px: 4,
      maxWidth:'1100px',
      [theme.breakpoints.down('md')]: {
        my: 10,
      }
    }}>
      <Typography variant="h5" gutterBottom>
        Завершенные обучения
      </Typography>
      <List sx={{ mb: 4 }}>
        {completedCourses.map((course, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${course.year} — ${course.title}`}
              secondary={course.institution}
              primaryTypographyProps={{ variant: 'body1' }} // Используем body1 для лучшего отображения
              secondaryTypographyProps={{ variant: 'body2' }} // Используем body2 для лучшего отображения
            />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" gutterBottom>
        Текущие программы
      </Typography>
      <List>
        {ongoingCourses.map((course, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${course.year} — ${course.title}`}
              secondary={course.institution}
              primaryTypographyProps={{ variant: 'body1' }} // Используем body1 для лучшего отображения
              secondaryTypographyProps={{ variant: 'body2' }} // Используем body2 для лучшего отображения
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CoursesBlock;
