import React from 'react';
import Slider from 'react-slick';
import { Box, Card, CardMedia, Typography, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Массив дипломов и сертификатов
const certificates = [
  { id: 1, image: '/img/sert1.jpg' },
  { id: 2, image: '/img/sert2.jpg' },
  { id: 3, image: '/img/sert3.jpg' },
  { id: 4, image: '/img/sert4.jpg' },
  { id: 5, image: '/img/sert5.jpg' },
  { id: 6, image: '/img/sert6.jpg' },
  { id: 7, image: '/img/sert7.jpg' },
  { id: 8, image: '/img/sert8.jpg' },
  // Добавьте свои изображения и заголовки
];

const CertificatesCarousel = () => {
  const sliderRef = React.useRef(null);
  const theme = useTheme();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Отключаем стандартные стрелки
  };

  return (
    <Box sx={{
      textAlign: 'center',
      my: 20,
      mx:'auto',
      maxWidth:'1100px',
      [theme.breakpoints.down('md')]: {
        my: 10,
      }
    }}>
      <Typography
        variant="h2"
        sx={{
          [theme.breakpoints.down('md')]: {
            px: 4,
          }
        }} 
      gutterBottom>
        Мое образование и квалификации
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <Slider {...settings} ref={sliderRef}>
          {certificates.map((cert) => (
            <Card key={cert.id} sx={{ maxWidth: 600, mx: 'auto', boxShadow: 3 }}>
              <CardMedia
                component="img"
                alt={cert.title}
                height="auto"
                image={cert.image}
              />
            </Card>
          ))}
        </Slider>
        <IconButton
          onClick={() => sliderRef.current.slickPrev()}
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            zIndex: 1,
            color: '#800020', // Акцентный цвет
            '&:hover': {
              color: '#600016', // Темнее бордо
            },
          }}
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={() => sliderRef.current.slickNext()}
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            zIndex: 1,
            color: '#800020', // Акцентный цвет
            '&:hover': {
              color: '#600016', // Темнее бордо
            },
          }}
        >
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CertificatesCarousel;
