import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const HeroSection = () => {
  const theme = useTheme();
  
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const yOffset = -70; /* подберите этот параметр для более гармоничной прокрутки */
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  
    if (element) {
      window.scrollTo({ top: y, behavior: 'smooth' });
      // element.scrollIntoView({  top: y, behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[200],
        '@media (max-width:600px)': {
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          width: '50%',
          [theme.breakpoints.down('md')]: {
            width: '100%',
            position: 'absolute',
            bottom: '70px',
            zIndex: 2, // Контент будет сверху изображения
            backgroundColor: theme.palette.grey[200],
          },
        }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            maxWidth: "495px",
            margin: '0 auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: theme.spacing(4),
          }}
        >
          <Typography variant="h1" 
            sx={{ 
              color: theme.palette.primary.main,
              marginBottom: theme.spacing(2),
            }}>
            Рябов Дмитрий. Психоаналитик
          </Typography>
          <Typography variant="body1"
                    sx={{ 
                      marginBottom: theme.spacing(2),
                    }}
                  >
            Вместе мы сможем разобраться с прошлым и заложить основу для перемен в будущем.
          </Typography>
          <Box>
            <Button variant="contained" color="primary" sx={{ marginRight: theme.spacing(2) }} onClick={() => scrollToSection('contact')}>
              Записаться
            </Button>
            <Button variant="outlined" color="primary" onClick={() => scrollToSection('social')}>
              Подробнее
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          width: '50%',
          backgroundImage: 'url(/img/my1.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100%',
          [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
          },
        }}
      />
    </Box>
  );
};

export default HeroSection;
