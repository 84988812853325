import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const PricingBlock = () => {
  const theme = useTheme();

  return (
    <Box sx={{
      textAlign: 'justify',
      my: 20,
      mx:'auto',
      px: 3,
      maxWidth:'1100px',
      [theme.breakpoints.down('md')]: {
        my: 10,
      }
    }}>
      <Typography variant="h2" gutterBottom sx={{ textAlign: 'center' }}>
      Стоимость и формат консультаций
      </Typography>
      <Card sx={{ maxWidth: 600, mx: 'auto', p: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" component="div" sx={{ textAlign: 'center' }} gutterBottom>
            Индивидуальная сессия
          </Typography>
          <Typography variant="h4" component="div" sx={{ mt: 1, textAlign: 'center'  }}>
            3000 ₽
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            <b>Продолжительность:</b> 50 минут
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            <b> Формат:</b> Онлайн (на удобной платформе)
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PricingBlock;
