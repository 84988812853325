import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Divider, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  const yOffset = -70; /* подберите этот параметр для более гармоничной прокрутки */
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  if (element) {
    window.scrollTo({ top: y, behavior: 'smooth' });
    // element.scrollIntoView({  top: y, behavior: 'smooth' });
  }
};

const Header = () => {
  const [open, setOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const mainRef = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      if (mainRef.current) {
        const mainBlockHeight = mainRef.current.getBoundingClientRect().height;
        if (window.scrollY > mainBlockHeight) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [mainRef]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: 0, right: 0, left: 0 }}
    >
      <Toolbar>
        {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Рябов Д. Психоаналитик
        </Typography> */}
        <IconButton
          color="inherit"
          edge="end"
          onClick={handleDrawerOpen}
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={open}
          onClose={handleDrawerClose}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleDrawerClose}
            onKeyDown={handleDrawerClose}
          >
            <List>
              <ListItem button onClick={() => scrollToSection('home')}>
                <ListItemText primary="Главная" />
              </ListItem>
              <ListItem button onClick={() => scrollToSection('social')}>
                <ListItemText primary="Соц. сети" />
              </ListItem>
              <ListItem button onClick={() => scrollToSection('about')}>
                <ListItemText primary="О психоанализе" />
              </ListItem>
              <ListItem button onClick={() => scrollToSection('pricing')}>
                <ListItemText primary="Цены" />
              </ListItem>
              <ListItem button onClick={() => scrollToSection('payment')}>
                <ListItemText primary="Оплата" />
              </ListItem>
              <ListItem button onClick={() => scrollToSection('contact')}>
                <ListItemText primary="Записаться" />
              </ListItem>
            </List>
            <Divider />
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
