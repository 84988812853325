import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTheme } from '@mui/material/styles';

const ContactBlock = () => {
  const theme = useTheme();

  return (
    <Box sx={{
      textAlign: 'justify',
      my: 20,
      mx:'auto',
      px: 4,
      maxWidth:'1100px',
      [theme.breakpoints.down('md')]: {
        my: 10,
      }
    }}>
      <Typography variant="h2" component="div" gutterBottom>
        Записаться на консультацию
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Чтобы записаться на консультацию, нажмите на кнопку удобного для вас мессенджера, напишите мне сообщение, и мы договоримся о времени встречи. Я постараюсь ответить вам как можно быстрее.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          startIcon={<TelegramIcon />}
          href="https://www.t.me/ryabov_psy"  // Замените на вашу ссылку
          target="_blank"
          rel="noopener noreferrer"
          sx={{ backgroundColor: '#0088cc', '&:hover': { backgroundColor: '#007ab8' } }}  // Цвет Telegram
        >
          Telegram
        </Button>
        <Button
          variant="contained"
          startIcon={<WhatsAppIcon />}
          href="https://wa.me/+79992195610"  // Замените на вашу ссылку
          target="_blank"
          rel="noopener noreferrer"
          sx={{ backgroundColor: '#25D366', '&:hover': { backgroundColor: '#1ebea5' } }}  // Цвет WhatsApp
        >
          WhatsApp
        </Button>
      </Box>
    </Box>
  );
};

export default ContactBlock;
