import React, { useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import WelcomeBlock from './components/WelcomeBlock';
import SocialBlock from './components/SocialBlock';
import PsychoanalysisBlock from './components/PsychoanalysisBlock';
import WorkingWith from './components/WorkingWith';
import CertificatesSlider from './components/CertificatesSlider';
import CoursesBlock from './components/CoursesBlock';
import PricingBlock from './components/PricingBlock';
import PaymentFormBlock from './components/PaymentFormBlock';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import { PrivacyPolicyProvider } from './PrivacyPolicyContext';

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';


import ContactBlock from './components/ContactBlock';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  const mainRef = useRef();

  return (
    <ThemeProvider theme={theme}>
    <PrivacyPolicyProvider>
      <CssBaseline />
      <Header />
      <div id="home" ref={mainRef}>
        <WelcomeBlock />
      </div>
      <div id="social">
        <SocialBlock />
      </div>
      <ContactBlock/>
      <div id="about">
        <PsychoanalysisBlock />
      </div>
      <WorkingWith />
      <CertificatesSlider />
      <CoursesBlock />
      <div id="pricing">
        <PricingBlock />
      </div>
      <div id="payment">
        <PaymentFormBlock />
      </div>
      <div id="contact">
        <ContactBlock/>
      </div>
      <Footer />

      <PrivacyPolicyModal />
    </PrivacyPolicyProvider>
    </ThemeProvider>
  );
}

export default App;
