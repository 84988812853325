import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usePrivacyPolicy } from './PrivacyPolicyContext';

const PrivacyPolicyModal = () => {
  const { open, closeModal } = usePrivacyPolicy();

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="privacy-policy-title"
      aria-describedby="privacy-policy-description"
      closeAfterTransition
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
          outline: 'none',
          textAlign: 'left',
          overflowY: 'auto',  // Добавлено для прокрутки, если текст длинный
          maxHeight: '80vh', // Ограничение высоты модального окна
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModal}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="privacy-policy-title" variant="h6" component="h2">
          Политика конфиденциальности
        </Typography>
        <Typography id="privacy-policy-description" variant="body2" sx={{ mt: 2 }}>
            <p>
                <b>1. Сбор информации</b>
                <br/>
                Мы собираем личные данные, такие как имя, номер телефона и адрес электронной почты, через формы на нашем сайте и мессенджеры. Эти данные необходимы для обработки вашей заявки на консультацию и обеспечения качественного обслуживания.
            </p>
            <p>
                <b>2. Использование информации</b>
                <br/>
                Ваши данные используются только для обработки заявок, предоставления консультаций и связи с вами по вопросам, связанным с нашими услугами. Мы не используем ваши данные в других целях.
            </p>
            <p>
                <b>3. Хранение и защита данных</b>
                <br/>
                Ваши данные хранятся в защищенных системах. Мы принимаем все необходимые меры для их защиты от несанкционированного доступа и утраты. Данные хранятся до тех пор, пока они необходимы для выполнения поставленных целей.
            </p>
            <p>
                <b>1. Сбор информации</b>
                <br/>
                Мы собираем личные данные, такие как имя, номер телефона и адрес электронной почты, через формы на нашем сайте и мессенджеры. Эти данные необходимы для обработки вашей заявки на консультацию и обеспечения качественного обслуживания.
            </p>
            <p>
                <b>1. Сбор информации</b>
                <br/>
                Мы собираем личные данные, такие как имя, номер телефона и адрес электронной почты, через формы на нашем сайте и мессенджеры. Эти данные необходимы для обработки вашей заявки на консультацию и обеспечения качественного обслуживания.
            </p>
            <p>
                <b>1. Сбор информации</b>
                <br/>
                Мы собираем личные данные, такие как имя, номер телефона и адрес электронной почты, через формы на нашем сайте и мессенджеры. Эти данные необходимы для обработки вашей заявки на консультацию и обеспечения качественного обслуживания.
            </p>
            <p>
                <b>1. Сбор информации</b>
                <br/>
                Мы собираем личные данные, такие как имя, номер телефона и адрес электронной почты, через формы на нашем сайте и мессенджеры. Эти данные необходимы для обработки вашей заявки на консультацию и обеспечения качественного обслуживания.
            </p>


4. Передача данных
Мы не передаем ваши данные третьим лицам, за исключением случаев, когда это требуется для обработки платежей и выполнения обязательств перед вами.
5. Права пользователя
Вы имеете право запросить доступ к своим данным, а также их изменение или удаление. Для этого свяжитесь с нами по указанным контактам.
6. Изменения в политике
Мы можем периодически обновлять эту политику. О любых изменениях мы будем уведомлять вас, публикуя обновленную версию на нашем сайте.
7. Контактная информация
Если у вас есть вопросы по поводу этой политики, пожалуйста, свяжитесь с нами по [вашему email] или по телефону [ваш телефон].
        </Typography>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicyModal;
