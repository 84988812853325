import React, {useEffect, useState} from 'react';
import { Box, Typography, Card, CardContent, TextField, Button, Link } from '@mui/material';
import InputMask from 'react-input-mask';
import { usePrivacyPolicy } from '../PrivacyPolicyContext';

const PaymentFormBlock = () => {
  const { openModal } = usePrivacyPolicy();

  const [formData, setFormData] = useState({
    amount: '',
    description: '',
    name: '',
    email: '',
    phone: '',
    receipt: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    openModal();
  };

    // Подключение скрипта от Т-Банка при монтировании компонента
    useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js";
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);

    const handleSubmit = (e) => {
      const {amount} = formData;
      e.preventDefault();
      e.target.receipt.value = JSON.stringify({
                  "EmailCompany": "ryabov.psy@gmail.com",
                  "Taxation": "usn_income",
                  "FfdVersion": "1.2",
                  "Items": [
                      {
                          "Name": "Оплата",
                          "Price": amount + '00',
                          "Quantity": 1.00,
                          "Amount": amount + '00',
                          "PaymentMethod": "full_prepayment",
                          "PaymentObject": "service",
                          "Tax": "none",
                          "MeasurementUnit": "pc"
                      }
                  ]
              });
          window.pay(e.target);
    };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundImage: 'url(/img/contacts.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: '0.75',
          zIndex: '-1',    
          left: 0,
          top: 0
        }}
      />
    <Box
      sx={{
        my: 6,
        mx: 'auto',
        position: 'relative',
        maxWidth: '1100px',
        py: 4,
        px: 3,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          maxWidth: 450,
          width: '100%',
          p: 2,
          boxShadow: 3,
          backgroundColor: 'background.paper',
          position: 'relative',
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
          Оплата консультации
          </Typography>
          <Box component="form" name="payform-tbank" id="payform-tbank" onSubmit={handleSubmit}>
            <input className="payform-tbank-row" type="hidden" name="terminalkey" value="1624365843059DEMO"/>
            <input className="payform-tbank-row" type="hidden" name="frame" value="false"/>
            <input className="payform-tbank-row" type="hidden" name="language" value="ru"/>
            <input type="hidden" name="description" value="Оплата за товар"/>
            <input className="payform-tbank-row" type="hidden" name="receipt" value={formData.receipt} />
            <input className="payform-tbank-row" type="hidden" placeholder="Номер заказа" name="order"/>
            <TextField
              label="Сумма"
              variant="outlined"
              fullWidth
              margin="normal"
              name="amount"
              type="number"
              required
              value={formData.amount}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="ФИО плательщика"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <InputMask
              mask="+7 (999) 999-99-99"
              value={formData.phone}
              onChange={handleInputChange}
            >
              {() => (
                <TextField
                  label="Номер Телефона"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="phone"
                  type="tel"
                  required
                  value={formData.phone}
                  sx={{ mb: 2 }}
                  // placeholder="+7 (999) 999-99-99"
                />
              )}
            </InputMask>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              Оплатить
            </Button>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">
                Вы будете перенаправлены на страницу банка для завершения оплаты.
              </Typography>
              <Typography variant="body2">
                `Ваши данные защищены. Ознакомьтесь с нашей `
                <Link href="#" onClick={handleLinkClick} underline="hover">
                    Политикой конфиденциальности
                </Link>.
              </Typography>

            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
    </Box>
  );
};

export default PaymentFormBlock;
